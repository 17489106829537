.container{
    max-width: 1800px !important;
}

.border-dark{
    border-color: #0D5195 !important;
    border-top: none !important;
    border-bottom: none !important;
    border-right: none !important;

}

.font-italic{
    font-style: italic !important;
}

@media print {
    .container {
      width: 100%;
      
    }
    .an {
      margin-top: 180px !important;
    }
    table {
        border-collapse: collapse;
        
        width: 100% !important;
      }
  
    .row {
      display: flex;
      flex-wrap: wrap;
     
      
    }
  
    .col-md-4 {
      width: 33.33%;
     
    }
       /* Ensure 2 cards per row for print */
        .col-sm-12 {
          width: 100%; /* Full width for small screens */
        }
    
        .col-md-6, .col-lg-6 {
          width: 50%; /* 2 cards per row for medium and large screens */
          box-sizing: border-box; /* Include padding and border in width */
        }
  
    .card {
     
      page-break-inside: avoid;
    }
    
  }
  .text-prim{
    color: #0D5195 !important;
  }
  .card{
border-color: #0D5195 !important;
}


.card-title{
    font-weight: 600 !important;
    color: #123d63;
}
.prim,td{
    color: #123d63 !important;
  
}
.card{
    min-height: 180px !important;
}
   
.overflowx{
    overflow-x: auto !important;
    display: flex ;
    justify-content: center;
    align-items: center;
}

  table {
    border-collapse: collapse;
    
    width: 90%;
  }
   td {
    padding: 8px;
  }  
  .mytable td {
    width: 50%; /* Adjust percentage as needed */
  }
  tr:nth-child(odd) {background-color: #f8f8ff; }
  td{
    border: 1px solid #c8c8c8;
    font-size: 18px;
  }
  td:nth-of-type(odd){
    border-left: none;
  }

  td:nth-of-type(even){
    border-right: none;
  }

  .border-black{
    border-color: #004985 !important;
    margin: 10px;
  }
  .bg-gradient{
    /* background: linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%) !important; */
    background: #015aa3e2 !important;
  }
  .bg-dark{
    background: #004985 !important;

  }
  .bg-gradient p{
    color: white ;
    text-transform: capitalize;
  }